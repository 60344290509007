import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Юридическое сопровождение сделок | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Юридическое сопровождение сделок - это залог успешного бизнеса. От того, насколько полно и грамотно составлен договор и соблюдены все этапы, предшествующие заключению сделки, зависит будущее сотрудничества." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/yuridicheskoe-soprovozhdenie-sdelok'
				name="Юридическое сопровождение сделок | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Юридическое сопровождение сделок - это залог успешного бизнеса. От того, насколько полно и грамотно составлен договор и соблюдены все этапы, предшествующие заключению сделки, зависит будущее сотрудничества. "
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Юридическое сопровождение сделок" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Юридическое сопровождение сделок"}
			
				html={

				`

				<p class="TextText">
				В ходе своей хозяйственной деятельности любое юридическое лицо занимается осуществлением сделок. Сделками признаются действия граждан и юридических лиц, направленные на установление, изменение или прекращение гражданских прав и обязанностей.
				</p>

				<p class="TextText">
				Юридическое сопровождение сделок - это залог успешного бизнеса. От того, насколько полно и грамотно составлен договор и соблюдены все этапы, предшествующие заключению сделки, зависит будущее сотрудничества. Нюансы, на которые юридическое лицо не обратит внимание при заключении договора, в дальнейшем могут обернуться большими денежными потерями или длительными судебными разбирательствами.
				</p>

				<p class="TextText">
				Поэтому при осуществлении юридическим лицом любой сделки необходимо обеспечить её юридическое сопровождение. Соблюсти все формальности проведения сделки лицу, не имеющему юридического образования и опыта проведения подобного рода сделок невозможно, поэтому юридическое сопровождение при подготовке и проведении сделок отнюдь не пустая трата средств, а наоборот - способ свести к минимуму возможные финансовые риски и временные потери юридического лица.
				</p>

				<p class="TextText">
				Проведенные юристом переговоры с контрагентами по сделке, позволят уже на начальном этапе взаимоотношений юридических лиц выгодно расставить акценты, которые в дальнейшем помогут избежать возможных недоразумений между сторонами при исполнении сделки.
				</p>

				<p class="TextText">
				Воспользовавшись услугами юристов по сопровождению сделок юридическое лицо получает дополнительные гарантии экономической и правовой безопасности при осуществлении своей хозяйственной деятельности.
				</p>

				<p class="TextText">
					<b>В рамках оказания услуги по сопровождению сделки, наши юристы могут оказать следующие услуги:</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					составление договоров, контрактов, соглашений, заключений
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					проведение правового анализа предложенных договоров, контрактов или соглашений
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					разработка и правовая экспертиза договорных схем
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					оценка правовых рисков заключаемых сделок
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					участие в переговорах с контрагентами
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					контроль за надлежащим исполнением совершенных сделок
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					регистрация отдельных видов сделок в государственных органах
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
